import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import { motion } from "framer-motion"

import Input from "components/Input"
import Button from "components/Button"
import SectionHeader from "components/SectionHeader"

import fadeAnimation from "utils/fadeAnimation"

const Newsletter = () => {
  const formID = "9"
  const [send, setSend] = useState(false)
  const [email, setEmail] = useState("")

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("email", email)
    formData.set("pageurl", location)
    formData.set("_wpcf7_unit_tag", '#wpcf7_unit_tag-'+formID)

    axios({
      method: "post",
      url: `https://cms.wallcraft.com.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        navigate("/newsletter-potwierdzenie/")
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <section className="newsletter">
      <div className="container-fluid">
        <SectionHeader
          title="Bądź na bieżąco,"
          content="zapisz się do newslettera"
        />
        <div className="row">
          <div className="col-lg-5 offset-lg-1">
            <motion.p {...fadeAnimation}>
              Dołącz do naszej społeczności i otrzymuj najnowsze informacje oraz
              inspirujące treści, które dostarczymy prosto do Twojej skrzynki
              odbiorczej. Zapisz się już teraz i nie przegap żadnej nowości!
            </motion.p>
          </div>

          <div className="col-lg-5 offset-lg-1">
            <motion.div {...fadeAnimation}>
              <form onSubmit={formSubmit}>
                <Input
                  placeholder="Twój adres email*"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <Button type="submit">
                  {send === true ? "Zapis w trakcie.." : "Dołączam"}
                </Button>
              </form>
              <small>
                Zapisując się do newselttera akceptujesz{" "}
                <a href="/polityka-prywatnosci/" traget="_blank">
                  Politykę Prywatności
                </a>
                .
              </small>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
